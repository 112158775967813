/* 
    Projects page
    Display more projects if they exist

*/


import viewModal from "./view-modal";

/* function readTextFile(file, callback) {
    var rawFile = new XMLHttpRequest();
    rawFile.overrideMimeType("application/json");
    rawFile.open("GET", file, true);
    rawFile.onreadystatechange = function() {
        if (rawFile.readyState === 4 && rawFile.status == "200") {
            callback(rawFile.responseText);
        }
    }
    rawFile.send(null);
} */

const MorePosts = {
    
    init() {
        
/*         if(typeof path_uri !== 'undefined') {

            const btnPosts = document.getElementById('btn-more-posts');


            const path_uri_assets = path_uri + '/assets/img/';
            let pathSVG = path_uri + '/svg_modal.json';
    
            let pictoCoinScroll ;
            let pictoVisiter ;
            let pictoVoir ;
            let pictoRetourhaut ;
    
            readTextFile(pathSVG , function(text){
                let data = JSON.parse(text);
                let svg_list = data.svg_list;
                let svg_extract_list = new Array();
    
                for (let j = 0; j< svg_list.length ; j++) {svg_extract_list.push(svg_list[j])}            
                pictoCoinScroll = svg_extract_list[0]['pictoCoinScroll'];
                pictoVisiter = svg_extract_list[1]['pictoVisiter'];
                pictoVoir = svg_extract_list[2]['pictoVoir'];
                pictoRetourhaut = svg_extract_list[3]['pictoRetourhaut'];
            }); */


            //Check if the button to load more projects exists - meaning there are more than 4 projects in the list
           /*  if(btnPosts) {
                btnPosts.addEventListener('click', function(e){
                    e.preventDefault();
                    let pathProjets = path_uri + '/projets.json';  
                    this.style.display = "none" ;
    
                    readTextFile(pathProjets , function(text){
                        let data = JSON.parse(text);
                        let projets = data.projets;
    
                        for(var i = 4; i < projets.length ; i++) {
                            let projet = projets[i];
                            let projet_id = projets[i].projet_id;
                            let title_projet = projets[i].title_projet;
                            let projet_img_thumbnail = projets[i].img_thumbnail;
                            let type_projet = projets[i].type_projet;
                            let lien_projet = projets[i].lien_projet;
                            let projet_client = projets[i].projet_client;
                            let description_projet = projets[i].description_projet;
                            let corps_de_la_modal = projets[i].corps_de_la_modal;
                            let lien_de_la_video = projets[i].lien_de_la_video;
                            let id_modal = projets[i].id_modal;
                        
                            // Creation of a project's different elements
                            let projetBlock = document.createElement('div');
                            let thumbnail_projet = document.createElement('img');
                            let overlay = document.createElement('div');
                                let typeProjet = document.createElement('div');
                                let clientProjet = document.createElement('div');
                            let ctasWrapper = document.createElement('div');
                                let ctaViewModal = document.createElement('a');
                                let ctaSite = document.createElement('a');
                            let modalContent = document.createElement('div');
                                let modalWrapper = document.createElement('div');
                                    let modalEntete = document.createElement('div');
                                        let infoScroll = document.createElement('div');
                                        let containerLarge = document.createElement('div');
                                            let modalTypeProjet = document.createElement('div');
                                                let typeProjetValue = document.createElement('span');
                                            let modalInfosProjet = document.createElement('div');
                                                let modalClient = document.createElement('div');
                                                let modalNomProjet = document.createElement('div');
                                                let modalDescriptionProjet = document.createElement('div');
                                    let modalCorps = document.createElement('div');
                                        let videoCorps = document.createElement('div');
                                            let containerVideo = document.createElement('div');
                                        let containerBtnTop = document.createElement('div');
                                            let BtnTop = document.createElement('div');
                                                let BtnTopAnchor = document.createElement('a');
                                            let BtnTopTxt = document.createElement('div');
                                            let BtnTopTxtSpan = document.createElement('span');
                                    let modalPiedPage = document.createElement('div');
                                        let containerPdP = document.createElement('div');
    
                            
                            // Add classes to these elements
                            projetBlock.classList.add('projet');
                            thumbnail_projet.classList.add('bg-img');
                            overlay.classList.add('overlay');
                            typeProjet.classList.add('type-projet');
                            clientProjet.classList.add('client');
                            ctasWrapper.classList.add('ctas-wrapper');
                            ctaViewModal.classList.add('cta-view-modal');
                            ctaSite.classList.add('cta-site');
                            modalContent.classList.add('modal--content');
                            modalWrapper.classList.add('modal--wrapper');
                            modalEntete.classList.add('modal--entete');
                            modalCorps.classList.add('modal--corps');
                            modalCorps.classList.add('container');
                            modalCorps.classList.add('large');
                            modalPiedPage.classList.add('modal--pied-de-page');
                            infoScroll.classList.add('infos-scroll');
                            containerLarge.classList.add('container');
                            containerLarge.classList.add('large');
                            containerVideo.classList.add('container');
                            containerVideo.classList.add('large');
                            containerPdP.classList.add('container');
                            containerPdP.classList.add('large');
                            containerBtnTop.classList.add('container');
                            containerBtnTop.classList.add('large');
                            modalTypeProjet.classList.add('modal--type-projet');
                            modalInfosProjet.classList.add('modal--infos-projet');
                            modalClient.classList.add('modal--client');
                            modalNomProjet.classList.add('modal--nom-projet');
                            modalDescriptionProjet.classList.add('modal--description-projet');
                            videoCorps.classList.add('video');
                            BtnTop.classList.add('btn-to-top');
                            BtnTopAnchor.classList.add('btn-scroll-top-modal');
                            BtnTopTxt.classList.add('btn-top-txt');
    
                            //Organisation of the elements
                            modalTypeProjet.appendChild(typeProjetValue);
    
                            modalInfosProjet.appendChild(modalClient);
                            if(!(projet_client !== "")) {
                                modalInfosProjet.appendChild(modalNomProjet);
                            }
                            
                            modalInfosProjet.appendChild(modalDescriptionProjet);
    
                            containerLarge.appendChild(modalTypeProjet);
                            containerLarge.appendChild(modalInfosProjet);
    
                            modalEntete.appendChild(infoScroll);
                            modalEntete.appendChild(containerLarge);
    
                            if(!(type_projet === "")) {
                                overlay.appendChild(typeProjet);
                            }
                            
                            overlay.appendChild(clientProjet);
    
                            ctasWrapper.appendChild(ctaViewModal);
                            if(!(lien_projet === "")) {
                                ctasWrapper.appendChild(ctaSite);
                            }
                            modalWrapper.appendChild(modalEntete);
                            modalWrapper.appendChild(modalCorps);
                            modalWrapper.appendChild(modalPiedPage);
    
                            modalContent.appendChild(modalWrapper);
    
                            projetBlock.appendChild(thumbnail_projet);
                            projetBlock.appendChild(overlay);
                            projetBlock.appendChild(ctasWrapper);
                            projetBlock.appendChild(modalContent);
    
                            corps_de_la_modal.forEach(e => {
                                let visuel = document.createElement("img");
                                visuel.src = e;
                                visuel.alt = "visuel projet";
                                modalCorps.appendChild(visuel);
                            });   
    
                            
                            videoCorps.appendChild(containerVideo);                        
                        
                            
                            
                            if(lien_de_la_video !== "") {
                                modalCorps.appendChild(videoCorps);
                            }
                            modalCorps.appendChild(containerBtnTop);
    
                            modalPiedPage.appendChild(containerPdP);

                            containerPdP.appendChild(BtnTop);
                            BtnTop.appendChild(BtnTopAnchor);
                            containerPdP.appendChild(BtnTopTxt);
                            
                            containerPdP.appendChild(BtnTopTxt);
                            BtnTopTxt.appendChild(BtnTopTxtSpan);
    

                            //Attributions of values - filling the html 
                            infoScroll.innerHTML = pictoCoinScroll; 
                            
                            if (type_projet) {
                                typeProjetValue.innerHTML = type_projet;
                            }
                            
                            modalClient.innerHTML = "Client : <span>" + title_projet + "</span>";
                            modalNomProjet.innerHTML = "Projet : <span>" + projet_client + "</span>";
                            modalDescriptionProjet.innerHTML = description_projet;
    
                            thumbnail_projet.src = projet_img_thumbnail;
                            thumbnail_projet.alt = "image du projet";
    
                            typeProjet.innerHTML = type_projet;
                            clientProjet.innerHTML = title_projet;
    
                            ctaViewModal.innerHTML = pictoVoir;
                            ctaViewModal.dataset.target = id_modal;
                            ctaSite.innerHTML = pictoVisiter;
                            ctaSite.setAttribute('target', '_blank');
                            ctaSite.href = lien_projet;
    
                            modalWrapper.id = id_modal;
    
                            containerVideo.innerHTML = lien_de_la_video;
                            BtnTopAnchor.innerHTML = pictoRetourhaut;
                            BtnTopTxtSpan.innerHTML = 'Revenir en haut'
                            
                            if(corps_de_la_modal.length !== 0) {
                                document.getElementById('ajax-posts').appendChild(projetBlock);
                            }

                            //same animation in project.animation.js
                            gsap.to('.projet', {opacity: 1, stagger: 0.2, ease: 'power2.inOut', delay: 0, duration: 1 })
                            gsap.to('.projet', {x: 50, stagger: 0.1, ease: 'power2.inOut', delay: 0, duration: 1.2 })
                            
                            viewModal.init();
                        }   
                    });
                })
            }
        } */

        const btnMorePosts = document.getElementById('btn-more-posts');

        if(btnMorePosts){

            const currentCat = btnMorePosts.dataset.cat;
            let currentPage = 0;

            btnMorePosts.addEventListener('click', () => {

                currentPage++; 
    
                $.ajax({
                    type: 'POST',
                    url: '/wp-admin/admin-ajax.php',
                    // url: '/taurine_site/wp-admin/admin-ajax.php',
                    
                
                    dataType: 'json',
                    data: {
                        action : 'btn_load_more',
                        current_cate_name: currentCat,
                        paged : currentPage,
                    },
    
                    success: function (res) {
                        
                        if(currentPage >= res.max) {
                        $('#btn-more-posts').hide();
                        }
                        $('.projects-wrapper').append(res.html);
                        //viewModal.init();
                        
                        function nextProjectsAnimation() {
                            const projet = document.querySelector('.project-card')
    
                            if(projet){
                                gsap.to('.project-card', {opacity: 1, stagger: 0.2, ease: 'power2.inOut', delay: 0, duration: 1 })
                                gsap.to('.project-card', {x: 50, stagger: 0.1, ease: 'power2.inOut', delay: 0, duration: 1.2 })
                            }
                        }
    
                        nextProjectsAnimation();
                    }
    
                
                })
    
    
            }) 
        }


       
    }
};

export default MorePosts;
