import toggleMenu from "./components/toggle-menu";
import scrollTop from "./components/scroll-top";
import sliderClients from "./components/slider-clients";
import contactForm from "./components/contact-form";
import viewModal from "./components/view-modal";
import morePosts from "./components/more-posts";
import sliderValues from "./components/slider-values";
import toggleMember from "./components/toggle-member";
import sliderExpertisesResponsive from "./components/slider-expertises-reponsive";
import switchPage from "./components/switch-page";
import arrowMenuCentered from "./components/arrowMenuCentered";
import projectsAnimation from "./components/projects-animation";
import toggleAgencyTabs from "./components/toggle-agencytabs";
import toggleValues from "./components/toggle-values-responsive";
import closeProject from "./components/close-project";

switchPage.init();
scrollTop.init();

sliderClients.init();
sliderValues.init();
sliderExpertisesResponsive.init();

contactForm.toggleForm();
contactForm.submitForm();

//viewModal.init();
morePosts.init();
closeProject.init();

toggleMenu.init();
toggleMember.init();
toggleAgencyTabs.init();
toggleValues.init();


arrowMenuCentered.init();
projectsAnimation.init();


document.addEventListener('DOMContentLoaded', (event) => {
    const home = document.querySelector('.home');

    if(home) {
        home.style.height = window.innerHeight + 'px';
    }
});

window.addEventListener('resize', function() {
    var homeResize = document.querySelector('.home');
    
    if(homeResize) {
        homeResize.style.height = window.innerHeight + 'px';
    }
});


var images = document.getElementsByTagName('img'); 

if(images){
    for(let i = 0; i < images.length ; i++){
        images[i].setAttribute('draggable', false);
    }
}