
/* 
    Projects page
    Animations
*/


const projectsAnimation = {

    init() {

        //LIST OF PROJECTS
        function initAnimProjects() {

            const projet = document.querySelector('.project-card');

            if(projet){
                gsap.to('.project-card', {opacity: 1, stagger: 0.2, ease: 'power2.inOut', delay: 0.5, duration: 1 })
                gsap.to('.project-card', {x: 50, stagger: 0.1, ease: 'power2.inOut', delay: 0.5, duration: 1.2 })
            }
        }

        window.addEventListener('load', initAnimProjects);


        //LIST OF CATEGORIES
        function initAnimCategories() {

            const category = document.querySelector('.category-item');

            if(category){
                gsap.to('.category-item', {opacity: 1, stagger: 0.2, ease: 'power2.inOut', delay: 0.5, duration: 1 })
                gsap.to('.category-item', {y: 0, stagger: 0.1, ease: 'power2.inOut', delay: 0.5, duration: 1.2 })
            }
        }

        window.addEventListener('load', initAnimCategories);

        //SINGLE PROJECT PAGE
        function initAnimProject() {

            const type = document.querySelector('.project--type');

            if(type){
                gsap.to('.project--type', {opacity: 1, stagger: 0.2, ease: 'power2.inOut', delay: 0.5, duration: 1 })
                gsap.to('.project--type', {x: 0, stagger: 0.1, ease: 'power2.inOut', delay: 0.5, duration: 1.2 })
            }

            const infos = document.querySelector('.project--infos');

            if(infos){
                gsap.to('.project--infos', {opacity: 1, stagger: 0.2, ease: 'power2.inOut', delay: 0.9, duration: 1 })
                gsap.to('.project--infos', {y: 0, stagger: 0.1, ease: 'power2.inOut', delay: 0.5, duration: 1.2 })
            }

            const info = document.querySelector('.project--info');

            if(info){
                gsap.to('.project--info', {opacity: 1, stagger: 0.2, ease: 'power2.inOut', delay: 0.9, duration: 1 })
                //gsap.to('.project--info', {x: 0, stagger: 0.1, ease: 'power2.inOut', delay: 0.5, duration: 1.2 })
            }

            const content = document.querySelector('.project--content');

            if(content){
                gsap.to('.project--content', {opacity: 1, stagger: 0.2, ease: 'power2.inOut', delay: 1.3, duration: 1 })
                gsap.to('.project--content', {y: 0, stagger: 0.1, ease: 'power2.inOut', delay: 1.3, duration: 1.2 })
            }
        }

        window.addEventListener('load', initAnimProject);

    }
}

export default projectsAnimation; 