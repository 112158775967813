/* 
    Animations when the user navigates between different pages
*/


const switchPage = {
    init() {
        const bgSwitchPage = document.querySelector('.bg-switch-page')
        const bgSwitchPageRed = document.querySelector('.bg-switch-page-red')
        const tlSwitchPage = gsap.timeline({
            default: {
                duration : 1
            }
        });
        const sideMenuList = document.querySelector(".side-menu-list")
        const sideMenuListItem = sideMenuList.children
        const logoSideMenu = document.querySelector(".logo-side-menu")
        const logoHome = document.querySelector(".logo-home")
        let logoSideMenuLink = logoSideMenu.firstChild.nextSibling
        let sideMenuListItemArray = Array.from(sideMenuListItem)

        if(sideMenuListItem) {
            sideMenuListItemArray.forEach(element => {
                let srcHref = element.firstChild.href;
    
                element.addEventListener('click', function(e) {
                    e.preventDefault()
                    tlSwitchPage
                    .fromTo(bgSwitchPage, {left: "100%"}, { left: 0, transformOrigin:"right", ease : "power4.inOut", duration: 0.1, onComplete: function() {
                        location.href = srcHref;
                    }})        
                })
            });
        }
        

        if(logoSideMenu) {
            logoSideMenuLink.addEventListener('click', (e) => {
                e.preventDefault()
                let src = logoSideMenuLink.getAttribute("href")
                tlSwitchPage
                .fromTo(bgSwitchPage, {left: "100%"}, { left: 0, transformOrigin:"right", ease : "power4.inOut", duration: .5, onComplete: function() {
                    location.href = src;
                }})   
            })
        }
        
        if(logoHome) {
            logoHome.addEventListener('click', (e) => {
                e.preventDefault()
                bgSwitchPage.style.background = "#a71c2a"
                let src = logoHome.getAttribute("href")
                tlSwitchPage
                .fromTo(bgSwitchPageRed, {scaleX: 0}, {left: 0, scaleX: 1, transformOrigin:"right", ease : "power4.inOut", duration: 1, autoAlpha: 1})   
                .fromTo(bgSwitchPage, {scaleX: 0}, {left: 0, autoAlpha: 1, scaleX: 1, transformOrigin:"right", ease : "power4.inOut", duration: .1, onComplete: function() {
                    location.href = src;
                }})   
            })
        }
    },
};

export default switchPage;