/* 

    Agency Page - Team tab 

    Add classes to each members for devices that use touch instead of hover

*/


const toggleMember = {
    init() {
        const TeamWrapper = document.getElementById('TeamWrapper');
        const members = document.querySelectorAll('.member');

        if(TeamWrapper){
            members.forEach(member => {
                member.addEventListener("click", function(e) {
                    e.preventDefault();
                
                    let hoverImg = member.querySelector('.member-hover-img');
                    hoverImg.classList.toggle("hoverImgActivated");
                    
                    let hoverNameJob = member.querySelector('.member-hover-name');
                    hoverNameJob.classList.toggle("hoverNameJobActivated");

                    let frontImg = member.querySelector('.front-img');

                    frontImg.classList.toggle("memberFrontDesactivated");

                    let frontMemberName = member.querySelector('.member-name');
                    frontMemberName.classList.toggle("memberFrontDesactivated");

                })

            });
        }
    },
};


export default toggleMember;