import toggleActiveClass from './toggle-activeclass';


/* 

    Agency Page 

    Manage the two buttons to display either the team tab or the office tab

*/

const toggleAgencyTabs = {
    init() {
        
        const ctaTeam = document.getElementById('ctaTeam');
        const ctaAgency = document.getElementById('ctaAgency');
        const TeamWrapper = document.getElementById('TeamWrapper');
        const AgencyWrapper = document.getElementById('AgencyWrapper');
        const ctaTeamWrapper = document.getElementById('ctaTeamWrapper');
        const ctaAgencyWrapper = document.getElementById('ctaAgencyWrapper');

        if(TeamWrapper){

            ctaTeam.addEventListener('click', function(e) {
                e.preventDefault();
                toggleActiveClass(ctaTeamWrapper, ctaAgencyWrapper, AgencyWrapper, TeamWrapper);
            });
            ctaAgency.addEventListener('click', function(e) {
                e.preventDefault();
                toggleActiveClass(ctaTeamWrapper, ctaAgencyWrapper, AgencyWrapper, TeamWrapper);
            });
        }
    },
};
  
export default toggleAgencyTabs;  