
/* 

    Expertise Page
    Slider
    
*/

const sliderExpertisesResponsive = {
    init() {

        var swiper = new Swiper(".swiper_expertise", {
            slidesPerView: 'auto',
            spaceBetween: 50,
            slidesPerGroup: 1,
            loop: true,
            loopFillGroupWithBlank: false,
            centeredSlides: true,
            autoHeight: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-expertise-next",
                prevEl: ".swiper-expertise-prev",
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                    slidesPerGroup: 1,
                    centeredSlides: false,
                    loopFillGroupWithBlank: true,
                    centeredSlides: false,

                },
                968: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                    slidesPerGroup: 1,
                    centeredSlides: false,
                    loopFillGroupWithBlank: true,
                },

                1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                    slidesPerGroup: 1,
                    centeredSlides: false,
                    loopFillGroupWithBlank: true,
                }
            }
        });

            //  ANIMATION
            function initAnimSliderExpertise() {

                const expertise = document.querySelector('.expertise-name');
    
                if(expertise){
                    gsap.to('.expertise-name', {opacity: 1, stagger: 0.2, ease: 'power2.inOut', delay: 0.5, duration: 1 })
                    gsap.to('.expertise-name', {x: '-50%', stagger: 0.1, ease: 'power2.inOut', delay: 0.5, duration: 1.2 })
                }
            }
    
            window.addEventListener('load', initAnimSliderExpertise);
    },
};

export default sliderExpertisesResponsive;