
/* Function to scroll to the top of the page  */

const scrollTop = {
    init() {
        // GLOBALE : Bouton scroll to top
        const btnToTop = document.getElementById("btnToTop");
        if (btnToTop) {
            btnToTop.addEventListener('click', function() {
                window.scrollTo({top: 0, behavior: 'smooth'});
            });
        }
    },
};

export default scrollTop;