
/* 
    Agency Page - Team tab 
    //SMALL DEVICES PHONE AND TABLET VERSION
    Manage the Slick carousel of the agency's values 
*/

const toggleValues = {
    init() {

        let values = document.querySelectorAll('.value-entete');

/*          for (var i = 0 ; i < values.length; i++) {
            values[i].addEventListener('click', function(){

                let valueName = this.id;
                let valuesDescriptionsList = document.getElementsByClassName('value-description');
                let valueToDisplay = document.getElementsByClassName('value-description ' + valueName);
                //console.log(valueToDisplay)

                if(valueToDisplay[0].classList.contains('value-description-active')){
                    valueToDisplay[0].classList.remove('value-description-active');
                } else {

                    for(let i=0; i < valuesDescriptionsList.length; i++){
                        valuesDescriptionsList[i].classList.remove('value-description-active');
                    }

                    valueToDisplay[0].classList.add('value-description-active');
                }
        
                }
            ); 
        }  */

        values.forEach(value => {
            value.addEventListener("click", function() {
                
                let valueName = this.id;
                let valuesDescriptionsList = document.getElementsByClassName('value-description');
                let valueToDisplay = document.getElementsByClassName('value-description ' + valueName);
                
                //Add a class to change the icon color when active 
                let valueIcon = value.querySelector('.value-icon-resp');
                let valueIconList = document.querySelectorAll('.value-icon-resp')
                

                if(valueIcon.classList.contains('icon-active')){
                    valueIcon.classList.remove('icon-active')
                } else {
                    for(let y=0; y < valueIconList.length; y++){

                        if(valueIconList[y].classList.contains('icon-active')){
                            
                            valueIconList[y].classList.remove('icon-active');
                        } 
                    }

                    valueIcon.classList.add('icon-active'); 
                }

                if(valueToDisplay[0].classList.contains('value-description-active')){
                    valueToDisplay[0].classList.remove('value-description-active');
                } else {

                    for(let i=0; i < valuesDescriptionsList.length; i++){
                        valuesDescriptionsList[i].classList.remove('value-description-active');
                    }

                    valueToDisplay[0].classList.add('value-description-active');
                }


            })
        })


    }
};

export default toggleValues;
