
/* 

    Expertise Page
    Slider displaying client's logos
    
*/


const sliderClients = {
    init() {

        var swiper = new Swiper(".mySwiperClient", {
            slidesPerView: 5,
            spaceBetween: 0,
            slidesPerGroup: 5,
            loop: true,
            loopFillGroupWithBlank: false,
            centeredSlides: false,
            speed: 2000,
            autoplay: { 
                delay: 3000,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-client-next",
                prevEl: ".swiper-client-prev",
            },

            breakpoints: {
                100: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                    slidesPerGroup: 1,
                    centeredSlides: false,
                    loopFillGroupWithBlank: true,
                    centeredSlides: false,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                    slidesPerGroup: 1,
                    centeredSlides: false,
                    loopFillGroupWithBlank: true,
                    centeredSlides: false,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 0,
                    slidesPerGroup: 5,
                    centeredSlides: false,
                    loopFillGroupWithBlank: false,
                    centeredSlides: false,
                },
            }
        });
    },
};

export default sliderClients;