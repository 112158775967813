
/* 
    Agency Page - Team tab 
    //DESKTOP VERSION (Small devices version -> values-toggle-responsive.js)
    Manage the Slick carousel of the agency's values 
*/

const sliderValues = {
    init() {

        $('.values-list').slick({
            vertical: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
        });
    }
};

export default sliderValues;