/* 
    Manage the burger menu
*/

const toggleMenu = {
    init() {
        const labelToggleRight = document.getElementById('toggle-sm-right');
        const labelToggleRightDiv = document.getElementById('toggle-sm-right-div');
        const labelToggleClose = document.getElementById('toggle-sm-close');
        const bgSideMenu  = document.querySelector('.bg-side-menu');
        const logoSideMenu  = document.querySelector('.logo-side-menu');
        const rsMenuContent  = document.querySelector('.rs-menu-content');
        const rsMenuWrapper = document.querySelector('.rs-menu-wrapper');
        const rsMenuItem = rsMenuWrapper.children;
        const sideMenuContent = document.querySelector('.side-menu-content');
        const sideMenuList = document.querySelector('.side-menu-list');
        const sideMenuListItem = sideMenuList.children; 
        const btnCloseMenu = document.querySelector('.btn-side-menu-close');
        const centerButton = document.querySelector('.toggle-menu');
        let homePage = document.querySelector('.home');
        
        const tlToggleMenu = gsap.timeline({
            defaults: {
                duration: 0.2
            },
            reversed: true
        });

        tlToggleMenu
        .fromTo(bgSideMenu, {right: "-150%"}, {right: "-50%", ease : "back.inOut(0.8)", duration: 0.6}, "-=0.25")
        .to(sideMenuContent, {x: "-50%",left: "50%", width: "auto", autoAlpha: 1, display: "block"})
        .to(logoSideMenu, { autoAlpha: 1, ease : "power4.inOut"}, "<")
        .to(sideMenuListItem,{duration: 0.2, stagger: {each: 0.2}, autoAlpha: 1, translateX: 0})
        .to(rsMenuContent, {left: "unset", right: 30, autoAlpha: 1, ease : "power4.inOut"})
        .to(labelToggleClose, {x: 50}, 1)
        .to(rsMenuItem, {opacity: 1, y: 0, stagger: 0.1, ease: 'power2.inOut', duration: 0.2 })
        
        
        function disableScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
            window.onscroll = function() {
                window.scrollTo(scrollLeft, scrollTop);
            };
        }

        function enableScroll() {
            window.onscroll = function() {};
        }

        if(labelToggleRight) {
            labelToggleRight.addEventListener('click', function(){
                tlToggleMenu.play();
                disableScroll();
                setTimeout(() => { btnCloseMenu.classList.add("active")}, 500)
                homePage.classList.add('no-scroll');
            });
        }

        if(labelToggleClose) {
            labelToggleClose.addEventListener('click', function(){
                tlToggleMenu.reverse();
                enableScroll();
                setTimeout(() => {btnCloseMenu.classList.remove("active")}, 2000);
                homePage.classList.remove('no-scroll');
            });
        }


        if(homePage){

            if(homePage.classList.contains('no-scroll') == false)
            homePage.addEventListener('wheel', (event) => {
                    tlToggleMenu.play();
                    disableScroll();
                    setTimeout(() => { btnCloseMenu.classList.add("active")}, 500);
                    homePage.classList.add('no-scroll');
            });
                
        }
     
        if(centerButton){
            centerButton.addEventListener('click', function(){
                tlToggleMenu.play();
                disableScroll();
                setTimeout(() => { btnCloseMenu.classList.add("active")}, 500)
                homePage.classList.add('no-scroll');
            });
        } 
    },
};

export default toggleMenu;