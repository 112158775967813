
/* 
    Project page
    Close the project page and goes back to the categorie the user is coming from
*/

const closeProject = {

    init() {

        let currentURL = location.pathname;

        let printURL = "/projet-categorie/print/";
        let motionURL = "/projet-categorie/motion/";
        let digitalURL = "/projet-categorie/digital/";


        if(currentURL === printURL || currentURL === motionURL || currentURL === digitalURL ){
            sessionStorage.setItem("backLink", currentURL);
        }
      
        const closeProjectBtn = document.getElementById("btn-close-project");

        if(closeProjectBtn){
            closeProjectBtn.addEventListener('click', function() {

                let backLink = sessionStorage.getItem("backLink");
          

                if(backLink){
                    //window.location.href = "http://taurine.local" + backLink;
                    window.location.href = "https://agencetaurine.fr" + backLink;

                } else {
                    window.location.href = 'https://agencetaurine.fr/projet-categorie/print/';
                } 
            })
        }
    }
}

export default closeProject;