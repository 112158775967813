import toggleActiveClass from './toggle-activeclass';

/* 
    Contact page
    Toggle between the two different forms
    and submit the form

*/


const contactForm = {

    toggleForm() {
        const ctaConsulter = document.getElementById('ctaConsultation');
        const ctaEmploi = document.getElementById('ctaEmploi');
        const ctaConsulterWrapper = document.getElementById('ctaConsultationWrapper');
        const ctaEmploiWrapper = document.getElementById('ctaEmploiWrapper');
        const consultationForm = document.getElementById('consultationForm');
        const emploiForm = document.getElementById('emploiForm');

        if(ctaConsulterWrapper){

            ctaConsulter.addEventListener('click', function(e) {
                e.preventDefault();
                toggleActiveClass(ctaConsulterWrapper, ctaEmploiWrapper, consultationForm, emploiForm);
            });
            ctaEmploi.addEventListener('click', function(e) {
                e.preventDefault();
                toggleActiveClass(ctaConsulterWrapper, ctaEmploiWrapper, consultationForm, emploiForm);
            });
        }


            //LIST OF PROJECTS
        function initAnimFormToggle() {

            const toggle = document.querySelector('.cta-consultation::before');

            if(toggle){
                //gsap.to('.cta-consultation', {opacity: 1, stagger: 0.2, ease: 'power2.inOut', delay: 0.5, duration: 1 })
                gsap.to('.cta-consultation::before', {height: 250, stagger: 0.1, ease: 'power2.inOut', delay: 0.5, duration: 1.2 })
            }
        }

        window.addEventListener('load', initAnimFormToggle);
    },

    submitForm() {
        const ctaEmploiSubmit = document.getElementById('ctaEmploiSubmit');  
        const ctaConsultationSubmit = document.getElementById('ctaConsultationSubmit');
        const consultation_button_submit = document.querySelector('.consultation_button_submit');
        const emploi_button_submit = document.querySelector('.emploi_button_submit');

        if(ctaEmploiSubmit) {
            ctaEmploiSubmit.addEventListener('click', function(e) {
                e.preventDefault();
                emploi_button_submit.click();       
            });
        }

        if(ctaConsultationSubmit) {
            ctaConsultationSubmit.addEventListener('click', function(e) {
                e.preventDefault();
                consultation_button_submit.click(); 
            });
        }
    } 
};
  
export default contactForm;